import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container , Row , Col } from 'reactstrap'
import { graphql } from "gatsby"
import Img from "gatsby-image"


export default class AlanadiPage extends React.Component {
  render() {
    const data = this.props.data
        const pagebgdomain = data.pagebgdomain.childImageSharp.fluid


return (
  <Layout pageTitle="Alan Adı (Domain)">
    <SEO title="Alan Adı Tescili - Domain" keywords={[`istanbul Web Tasarım`, `Alan Adı`, `Domain`]} description="İstanbul Web Tasarım Farkı ile uygun fiyata alan adınızı bugünden kaydedin!" />

    <section className="bginpage">
    <Img fluid={pagebgdomain}
    style={{
                   position: 'absolute',
                   left: 0,
                   top: 0,
                   width: '100%',
                   height: '100%',
                 }} alt="Alan Adı Tescili"/>
    <Container>
      <Row>
        <Col md="5">
        <div className="bginpagedark">
        <h1 className="mb20  section-title">
       Alan Adı ( Domain )
             </h1>
             <p>Herşey iyi bir alan adı ile başlar.Alan Adınızı bugünden kaydederek marka kurgunuzu yükseklere taşıyın!İstanbul Web Tasarım bünyesinde alan Adı barındırma ve alan adı tescili hizmetlerini profesyonel olarak sunar.</p>
      </div>  </Col>
        </Row>
        </Container>
  </section>
  <section className="maincontent">
  <Container>

    <Row>
      <Col md="12" className="acik">
      <h1 className="font-size-normal  section-title">
               <small>Web Tasarım &amp; E-Ticaret &amp; Arama Motoru Optimizasyonu &amp; Kurumsal e-Posta</small>
               Alan Adı ( Domain )
                <div className="bar"></div>
           </h1>
  <p>
    Domain mi Satın Almak İstiyorsunuz?

Aklınızda kurumsal kimliğinize yakışır bir domain mi var?Öyleyse ne duruyorsunuz hemen uygun fiyatlarla sizde isminizi tescil edin.

Domain nedir?

Domain sizlerin internet üzerinde internet explorer chrome gibi web tarayıcılarında üst kısma yazdığınız alan adı olarak tabir edilen ve sitenizin bilgileri ile eşlesen özel isimlerdir.Bu noktada dikkat edilmesi gereken husus internet ortamında kullanacağınız isminizin sizi en iyi şekilde ifade etmesi arama motorları tarafından anlaşılabilir olması kısmende anahtar sözcükler içermesi ve akılda kolayu tutulabilir olması sizlerin internet kullancılarını daha kolay ulaşabilir yapıcaktır.

İstanbulwebtasarim domain hizmetini control telekominikasyon ve bilişim hizmetlerinden almaktadır.

Domain seçimindeki kısmı hususlar şu şekilde sergilenebilir;
.com – .org -.net gibi alan isimleri tescilinde herhangi bir belge gerekmemektedir.Herhangi bir internet kullanıcısı sizin istediğiniz web alanını her an tescil edip kullanmaya başlayabileceği gibi bir riskiniz ne yazık ki mevcut bundan dolayı isminizi bir an önce tescil ettirmeniz de fayda vardır.

Peki siz firmanızı bir marka yapmak istiyorsunuz internet ortamında bu noktada Türkiye uzantılı alan adlarında .com.tr gibi alan adları mevcuttur.Bu alan adlarının tescili ODTÜ tarafından yapılmakla beraber normal alan adları fiyatlarından farklıdır fiyatları.bu kısımı incelemek için lütfen marka tescili bölümüzü inceleyiniz
</p>
    </Col>
    </Row>
    </Container>
    </section>
  </Layout>
)

}
}
export const pageQuery = graphql`
query {
pagebgdomain: file(
 relativePath: { regex: "/bg12.jpg/" }
) {
childImageSharp {
           fluid(quality: 100){
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
}
`
